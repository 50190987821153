import { Injectable } from '@angular/core';

import { FormDataModel } from 'src/app/_models';

import { ProductForm } from 'src/app/_constants';



@Injectable({
  providedIn: 'root'
})
export class ComponentFormDataService {
  constructor() { }

  getComponentDataForm(url: string): FormDataModel {
    if (url === 'products') {
      return ProductForm;
    }
  }
}
