import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  private readonly SESSION_ID: string = 'SESSION_ID';
  private readonly MID: string = 'MID';

  constructor() { }

  getSessionId() {
    return localStorage.getItem(this.SESSION_ID);
  }

  setSessionId(id) {
    localStorage.setItem(this.SESSION_ID, id);
  }

  merchantId(id) {
    localStorage.setItem(this.MID, id);
  }


  isUserLogin() {
    return !!this.getSessionId();
  }

  doLogout() {
    localStorage.removeItem(this.SESSION_ID);
    localStorage.removeItem(this.MID);
  }

}
