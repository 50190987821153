import { CanActivate, Router } from '@angular/router';
import { StorageService } from 'src/app/_services';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class OtherGuard implements CanActivate {

    constructor(private storage: StorageService, private router: Router) {}

    canActivate() {
        if (this.storage.isUserLogin()) {
            this.router.navigate(['/']);
        }
        return !this.storage.isUserLogin();
    }

}
