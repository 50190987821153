import { FormData } from 'src/app/_interfaces';

export class FormDataInputModel implements FormData {

  id: number;
  name: string;
  placeholder: string;
  label: string;
  labelInfo: string;
  tableMeta: any;
  formMeta: any;
  fieldType: any;
  required: boolean;
  readonly: boolean;
  errorMsg: string;
  default: any;
  isTableHeader: boolean;
  isImageSowable: boolean;

  constructor(formData: any) {
    this.id = formData.id;
    this.name = formData.name;
    this.placeholder = formData.placeholder;
    this.label = formData.label;
    this.labelInfo = formData.labelInfo || null;
    this.tableMeta = formData.tableMeta;
    this.formMeta = formData.formMeta;
    this.fieldType = formData.fieldType;
    this.required = formData.required;
    this.readonly = formData.readonly;
    this.errorMsg = formData.errorMsg;
    this.default = formData.default;
    this.isTableHeader = formData.isTableHeader || false;
    this.isImageSowable = formData.isImageSowable || false;
  }

  get getPayloadKey(): string {
    return this.name;
  }

  get getPayloadValue(): any {
    return this.default;
  }

}

export class FormDataModel {

  private formInputData: Array<FormDataInputModel>;

  constructor(private formDataModelArray: Array<any>) {
    this.formInputData = this.formDataModelArray.map(
      x => new FormDataInputModel(x)
    );
  }

  getData() {
    return this.formInputData;
  }

  getPageTableHeaders() {
    const tableField = this.formInputData.filter(d => d.isTableHeader);

    const tableHeaders = tableField.map(x => {
      return {
        tableKey: x.name,
        tableLabel: x.tableMeta.title,
        enableSort: x.tableMeta.enableSort,
        type: x.tableMeta.type || 'default'
      };
    });
    return [
      {
        tableKey: 'id',
        tableLabel: 'Id',
        enableSort: true,
        type: 'default'
      },
      ...tableHeaders
    ];
  }

}
