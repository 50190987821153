import { FormDataModel } from 'src/app/_models';

const ordersRawArray = [
    {
        name: 'orderid',
        isTableHeader: true,
        tableMeta: { title: 'OrderId', enableSort: true },
        label: 'orderid',
      },
      {
        name: 'data',
        isTableHeader: true,
        tableMeta: { title: 'Data', enableSort: true },
        label: 'data',
      },
      {
        name: 'customer',
        isTableHeader: true,
        tableMeta: { title: 'Customer', enableSort: true },
        label: 'customer',
      },
      {
        name: 'payment',
        isTableHeader: true,
        tableMeta: { title: 'Payment', enableSort: true },
        label: 'payment',
      },
      {
        name: 'image',
        isTableHeader: true,
        tableMeta: { title: 'Image', enableSort: false },
        label: 'image',
      },

];

export const OrdersForm: FormDataModel = new FormDataModel(ordersRawArray);
