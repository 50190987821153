import { FormDataModel } from 'src/app/_models';

const subProductRawArray = [
    {
        name: 'name',
        isTableHeader: true,
        tableMeta: { title: 'Name', enableSort: true },
        label: 'Name',
      },
      {
        name: 'qty',
        isTableHeader: true,
        tableMeta: { title: 'Quantity', enableSort: false },
        label: 'quantity',
      },
      {
        name: 'sku',
        isTableHeader: true,
        tableMeta: { title: 'SKU', enableSort: false },
        label: 'sku',
      },
      {
        name: 'variant_name',
        isTableHeader: true,
        tableMeta: { title: 'Variant Name', enableSort: false },
        label: 'variantName',
      },
      {
        name: 'price',
        isTableHeader: true,
        tableMeta: { title: 'Price', enableSort: false },
        label: 'price',
      },
      {
        name: 'slug',
        isTableHeader: false,
        tableMeta: { title: 'Slug', enableSort: false },
        label: 'slug',
      },

];

export const SubProductForm: FormDataModel = new FormDataModel(subProductRawArray);
