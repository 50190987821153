import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { tap, first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { StorageService } from 'src/app/_services';
import { MessageService } from 'src/app/_services';
import { UserService } from 'src/app/_services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  isCredentialTrue: boolean;
  loginForm: FormGroup;

  constructor(

    private userService: UserService,
    private storage: StorageService,
    private message: MessageService,
    private fb: FormBuilder,
    private route: Router,

  ) { }

  ngOnInit(): void {

    this.loginForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });

  }

  submitForm(event): void {

    event.preventDefault();
    for (const key in this.loginForm.controls) {
      if (this.loginForm.controls.hasOwnProperty(key)) {
        this.loginForm.controls[key].markAsDirty();
        this.loginForm.controls[key].updateValueAndValidity();
      }
    }
    if (this.loginForm.valid) {
      this.postLoginApi(this.loginForm.value);
    }

  }

  postLoginApi(userData ?: any) {
     console.log('data----', userData);
    this.userService.loginAPI('team/signin', userData).pipe(first())
      .subscribe(
        response => {
          console.log("response--", response);
          if (response.status_code === 200 && response.data) {
            this.storage.setSessionId(response.data.token);
            this.storage.merchantId(response.data.merchant_id);
            this.message.showSuccess(response.message);
            this.route.navigateByUrl('/component/merchants');
          } else if(response.status_code === 401){
            this.message.showError("invalid username or password!");
          }else {
            this.message.showError(response.message);
          }
        },
        error => {
          this.message.showError();
        });

  }

}