import { FormDataModel } from 'src/app/_models';

const productRawArray = [

  {
    name: 'name',
    placeholder: 'Enter Product Name Here',
    isTableHeader: true,
    tableMeta: { title: 'Name', enableSort: true },
    label: 'Product Name',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: true,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'sku',
    placeholder: 'Enter SKU Here',
    isTableHeader: true,
    tableMeta: { title: 'SKU', enableSort: true },
    label: 'Sku Name',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: true,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'variantType',
    placeholder: 'Enter variantType Here',
    isTableHeader: true,
    tableMeta: { title: 'Variant Type', enableSort: true },
    label: 'Variant Type',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: true,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'subProductCount',
    //placeholder: 'Enter type Here',
    isTableHeader: true,
    tableMeta: { title: 'No. of Sub-Products', enableSort: false },
    label: 'No. of Sub-Products',
  },

  // {
  //   name: 'view',
  //   placeholder: 'Enter view Here',
  //   isTableHeader: true,
  //   tableMeta: { title: 'View', enableSort: false },
  //   label: 'Type',
  // },

  {
    name: 'image',
    placeholder: 'Enter image Here',
    isTableHeader: false,
    formMeta: { width: 100 },
    tableMeta: {
      title: 'Images',
      enableSort: false,
      type: 'image'
    },
    label: 'Images',
    labelInfo: 'width & height = 470px & 336px',
    fieldType: {
      name: 'singleImage',
      subfieldType: 'singleImage'
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'previousPrice',
    placeholder: 'Enter Previous Price Here',
    isTableHeader: false,
    tableMeta: { title: 'Previous Price', enableSort: false },
    label: 'Previous Price',
    labelInfo: 'only two decimal precision allowed (eg -> 4.77)',
    fieldType: {
      name: 'text',
      subfieldType: 'number'
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'currentPrice',
    placeholder: 'Enter Current Price Here',
    isTableHeader: false,
    tableMeta: { title: 'Current Price', enableSort: false },
    label: 'Current Price',
    formMeta: { width: 50 },
    labelInfo: 'only two decimal precision allowed (eg -> 4.77)',
    fieldType: {
      name: 'text',
      subfieldType: 'number'
    },
    required: true,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'info',
    placeholder: 'Enter Product Info Here',
    isTableHeader: false,
    formMeta: { width: 50 },
    tableMeta: { title: 'Info', enableSort: false },
    label: 'Product Info',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'rating',
    placeholder: 'Enter rating Here',
    isTableHeader: false,
    tableMeta: { title: 'Rating', enableSort: false },
    label: 'Rating',
    labelInfo: 'only one decimal precision allowed (eg -> 4.7)',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'smallDesc',
    placeholder: 'Enter Product Description Here',
    tableMeta: { title: 'Description', enableSort: false },
    label: 'Description - 1',
    fieldType: {
      name: 'textarea',
      subfieldType: 'text',
      row: 4
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'mainDesc',
    placeholder: 'Enter Product Description Here',
    tableMeta: { title: 'Main Description', enableSort: false },
    label: 'Description - 2',
    fieldType: {
      name: 'textarea',
      subfieldType: 'text',
      row: 4
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },

];

export const ProductForm: FormDataModel = new FormDataModel(productRawArray);
