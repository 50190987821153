import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { BaseService } from '../_base/base.service';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService {

  host = 'http://localhost:4000/users/authenticate';

  loginAPI(entityName ,userData) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.post<any>(url, userData)
      .pipe(map(user => {
        return user;
      }));
  }

}
