import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import {
  registerLocaleData,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';
import en from '@angular/common/locales/en';

import { mockAPIDataProvider } from './_mockData';

import { AppRoutingModule } from './app-routing.module';
import { App as AppConfig } from './_config';
import { ApiRoutes } from './_config';
import { AppComponent } from './app.component';

import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { NzInputModule } from 'ng-zorro-antd/input';

import { LoginComponent } from './_authentication/login/login.component';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    AppRoutingModule,
    HttpClientModule,
    DragDropModule,
    BrowserModule,
    NzInputModule,
    FormsModule,
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: NZ_I18N, useValue: en_US },
    mockAPIDataProvider,
    AppConfig,
    ApiRoutes
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
