import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable()

export class ApiRoutes {

  private apiVersionUrl = environment.server.apiVersionPath;
  private apiBaseUrl = environment.server.apiBaseUrl;
  private apiSecretKey = environment.server.apiSecret;
  private apiSecretKeyName = environment.server.apiSecretKeyName;
  private apiTokenKeyName = environment.server.apiTokenKeyName;
  private imageHostingBaseUrl = environment.server.imageHostingBaseUrl;

  constructor() { }

  get getApiSecretKeyName(): string {
    return this.apiSecretKeyName;
  }

  get getApiTokenKeyName(): string {
    return this.apiTokenKeyName;
  }

  get getApiSecretKey(): string {
    return this.apiSecretKey;
  }

  get getApiBaseUrl(): string {
    return this.apiBaseUrl + this.apiVersionUrl;
  }


  get getImageHostingBaseUrl(): string {
    return this.imageHostingBaseUrl;
  }

  // ================= API URLs Here =================

  get getAbstractAPIUrl(): string {
    return this.getApiBaseUrl;
  }

  // ================= USER AUTH API's =================

  get getUserLoginAPIUrl(): string {
    return this.getApiBaseUrl + '/users/authenticate';
  }

  // ================= Assets API URLs Here =================

  get getImageUploadAPIUrl(): string {
    return this.getApiBaseUrl + '/upload';
  }

  get getImagesAPIUrl(): string {
    return this.getApiBaseUrl + '/images';
  }

  // ================= Tags API's =================

  get getTagsAPIUrl(): string {
    return this.getApiBaseUrl + '/tags';
  }

  // ================= Pages API's =================

  get getPagesAPIUrl(): string {
    return this.getApiBaseUrl + '/pages';
  }

}
