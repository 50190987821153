import { FormDataModel } from 'src/app/_models';

const BannerRawArray = [

  {
    name: 'name',
    placeholder: 'Enter Banner Name Here',
    isTableHeader: true,
    tableMeta: { title: 'Name', enableSort: true },
    label: 'Banner Name',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: true,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'image',
    placeholder: 'Enter image Here',
    isTableHeader: true,
    formMeta: { width: 100 },
    tableMeta: {
      title: 'Images',
      enableSort: false,
      type: 'image'
    },
    label: 'Images',
    labelInfo: 'width & height = 470px & 336px',
    fieldType: {
      name: 'singleImage',
      subfieldType: 'singleImage'
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'url',
    placeholder: 'Enter Banner Url Here',
    isTableHeader: true,
    formMeta: { width: 50 },
    tableMeta: { title: 'Url', enableSort: true },
    label: 'Banner Url',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: false,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  }

];

export const BannerForm: FormDataModel = new FormDataModel(BannerRawArray);
