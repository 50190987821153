import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { App as AppConfig } from 'src/app/_config';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BaseService } from '../_base/base.service';
import { Helper } from 'src/app/_utils';

@Injectable({
  providedIn: 'root'
})
export class AbstractComponentService extends BaseService {

  getAllComponentsObjectsAPI(apiSlug: string , params: any = {}) {
    const url = this.getApiRoutes.getAbstractAPIUrl + '/' + apiSlug + Helper.getQueryParamsString(params);
    return this.httpClient
      .get<any>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + 'cmFtOk1haGlAMTIzNA=='
        })
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

}
