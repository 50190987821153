import { FormDataModel } from 'src/app/_models';

const HomeFormArray = [
    {
        name: 'name',
        placeholder: 'Enter Page name Here',
        tableMeta: { title: 'Page Name', enableSort: true },
        label: 'Page Name',
        fieldType: {
            name: 'text',
            subfieldType: 'text'
        },
        required: true,
        readonly: true,
        errorMsg: 'Can\'t be left blank',
        default: ''
    },
    {
        name: 'pageIdentifier',
        placeholder: 'Enter Page Identifier Here',
        tableMeta: { title: 'Page Identifier', enableSort: true },
        label: 'Page Identifier',
        fieldType: {
            name: 'text',
            subfieldType: 'text'
        },
        required: true,
        readonly: true,
        errorMsg: 'Can\'t be left blank',
        default: ''
    },
    {
        name: 'helpEmail',
        placeholder: 'Enter Contact Help Email Here',
        tableMeta: { title: 'Contact Help Email', enableSort: false },
        label: 'Contact Help Email',
        fieldType: {
            name: 'attribute',
            subfieldType: 'text'
        },
        required: true,
        readonly: true,
        errorMsg: 'Can\'t be left blank',
        default: 0
    },
    {
        name: 'helpNumber',
        placeholder: 'Enter Contact Helpline Number Here',
        tableMeta: { title: 'Contact Helpline Number', enableSort: false },
        label: 'Contact Helpline Number',
        fieldType: {
            name: 'attribute',
            subfieldType: 'text'
        },
        required: true,
        readonly: true,
        errorMsg: 'Can\'t be left blank',
        default: 0
    },
    {
        name: 'seo',
        label: 'Enter Seo Detail',
        labelInfo: 'Please enter seo detail carefully',
        formMeta: { width: 100 },
        fieldType: {
            name: 'seo'
        },
        required: true,
        readonly: false,
        default: {
            title: 'Title',
            description: 'Description',
            keywords: 'Keywords',
            canonical_urls: 'Canonical URls',
            og_urls: '',
            og_site_url: '',
            og_title: '',
            og_description: '',
            og_type: '',
            og_locale: '',
            og_image: '',
            json: '{"name": "Raj"}'
        }
    }
];

export const HomeForm: FormDataModel = new FormDataModel(HomeFormArray);
