<div class="login-page-container">
  <div class="login-form-container">
    <div class="title">Sign in</div>
    <div class="subtitle">Enter your details below</div>
    <form nz-form [formGroup]="loginForm" class="login-form" (ngSubmit)="submitForm($event)"
      (keyup.enter)="submitForm($event)">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your email!">
          <nz-input-group nzPrefixIcon="user">
            <input type="text" nz-input formControlName="email" placeholder="email" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group nzPrefixIcon="lock">
            <input type="password" nz-input formControlName="password" placeholder="Password" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="m-t-20">
        <nz-form-control>
          <!-- <label nz-checkbox formControlName="remember">
            <span>Remember me</span>
          </label> -->
          <a class="login-form-forgot" class="login-form-forgot">Forgot password</a>
          <div class="login-form-button">
            <button (click)="submitForm($event)"  nz-button nzType="default">Log in</button>
          </div>
          <!-- <a>register now!</a> -->
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>
