import { Observable, throwError } from 'rxjs';
import { StorageService } from './_services';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHeaders,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AppHttpIntercepterService {

  constructor() { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (true) {
      const userToken = localStorage.getItem('SESSION_ID') ? localStorage.getItem('SESSION_ID') : null;
      const merchantid = localStorage.getItem('MID') ? localStorage.getItem('MID') : null;
      const ss = new StorageService();
      const httpHeaders = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set('Authorization', `Bearer ${userToken}`)
      const cloned = req.clone({
        headers: httpHeaders
      });
      return next.handle(cloned).pipe(catchError(this.handleError));
    } else {
      return next.handle(req).pipe(catchError(this.handleError));
    }
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }

}
