import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { App as AppConfig } from 'src/app/_config';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class BaseService {

  constructor(
    protected httpClient: HttpClient,
    protected appConfig: AppConfig,
    protected router: Router,
  ) { }

  get getHttpClient() {
    return this.httpClient;
  }


  get getAppConfig() {
    return this.appConfig;
  }

  get getApiRoutes() {
    return this.getAppConfig.getApiRoutes;
  }

}
