import { AppHttpIntercepterService } from './app-http-intercepter.service';
import { LoginComponent } from './_authentication/login/login.component';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OtherGuard } from './_guards';
import { AuthGuard } from './_guards';

const routes: Routes = [

  {
    path: 'login',
    canActivate: [OtherGuard],
    component: LoginComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./_main/main/main.module')
        .then(m => m.MainModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpIntercepterService,
      multi: true
    }
  ]
})

export class AppRoutingModule { }

