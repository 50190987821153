import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseService } from '../_base/base.service';
import { ContactForm, HomeForm } from 'src/app/_constants';
import { FormDataModel } from 'src/app/_models';

@Injectable({
  providedIn: 'root'
})

export class PagesService extends BaseService {

  getPageDataForm(url: string): FormDataModel {
    if (url === 'contact') {
      return ContactForm;
    }else if(url === 'home') {
      return HomeForm;
    }
  }

  getPageHeaderForm(url: string) {
    if (url === 'contact') {
      return 'Contact';
    }else if(url === 'contact'){
      return 'Home';
    }
  }

  getPageDataAPI(pageUrl) {
    const url = this.getApiRoutes.getPagesAPIUrl + '/' + pageUrl;
    return this.httpClient.get<any>(url).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  editPageDataAPI(id: number, pageData) {
    const url = this.getApiRoutes.getPagesAPIUrl + '/' + id;
    return this.httpClient.put<any>(url, pageData).pipe(
      map(res => {
        return res.data;
      })
    );
  }

}
