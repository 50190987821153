import { FormDataModel } from 'src/app/_models';

const SeoWidgetArray = [

  {
      name: 'title',
      placeholder: 'Enter title name Here',
      tableMeta: { title: 'Title', enableSort: false },
      label: 'Title',
      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: true,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'description',
      placeholder: 'Enter desciption here',
      tableMeta: { title: 'Description', enableSort: false },
      label: 'Description',
      fieldType: {
          name: 'textarea',
          subfieldType: 'text'
      },
      required: true,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'keywords',
      placeholder: 'Enter keywords here',
      tableMeta: { title: 'Keywords', enableSort: false },
      label: 'Keywords',
      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: true,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'canonical_urls',
      placeholder: 'Enter canonical url here',
      tableMeta: { title: 'Canonical URLs', enableSort: false },
      label: 'Canonical URls',
      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: true,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'og_urls',
      placeholder: 'Enter og url here',
      tableMeta: { title: 'Og URLs', enableSort: false },
      label: 'Og URls',
      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: false,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'og_site_url',
      placeholder: 'Enter og site_url here',
      tableMeta: { title: 'Og site_url', enableSort: false },
      label: 'Og site_url',
      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: false,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'og_title',
      placeholder: 'Enter og title here',
      tableMeta: { title: 'Og Title', enableSort: false },
      label: 'Og Title',
      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: false,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'og_description',
      placeholder: 'Enter og description here',
      tableMeta: { title: 'Og description', enableSort: false },
      label: 'Og Description',
      fieldType: {
          name: 'textarea',
          subfieldType: 'text'
      },
      required: false,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'og_type',
      placeholder: 'Enter og type here',
      tableMeta: { title: 'Og Type', enableSort: false },
      label: 'Og Type',

      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: false,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'og_locale',
      placeholder: 'Enter og locale here',
      tableMeta: { title: 'Og Locale', enableSort: false },
      label: 'Og Locale',
      fieldType: {
          name: 'text',
          subfieldType: 'text'
      },
      required: false,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'og_image',
      placeholder: 'Enter og image here',
      tableMeta: { title: 'Og Image', enableSort: false },
      label: 'Og Image',
      formMeta: { width: 100 },
      fieldType: {
          name: 'singleImage'
      },
      required: false,
      readonly: false,
      errorMsg: 'Can\'t be left blank',
      default: ''
  },
  {
      name: 'json',
      placeholder: 'Enter json here',
      tableMeta: { title: 'JSON', enableSort: false },
      label: 'JSON Schema',
      formMeta: { width: 100 },
      id: 4,
      fieldType: {
          name: 'jsonText',
          subfieldType: 'jsonText'
      },
      required: false,
      readonly: false,
      errorMsg: 'JSON Invalid',
      default: ''
  }

];

export const SeoWidgetForm: FormDataModel = new FormDataModel(SeoWidgetArray);
