import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from '../_base/base.service';
import { Helper } from 'src/app/_utils';

@Injectable({
  providedIn: 'root'
})

export class TagsService extends BaseService {

  createTagAPI(tag, params: any = {}) {
    const url = this.getApiRoutes.getTagsAPIUrl + Helper.getQueryParamsString(params);
    return this.httpClient
      .post<any>(url, tag, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + 'cmFtOk1haGlAMTIzNA=='
        })
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getAllTagsAPI(params: any = {}) {
    const url = this.getApiRoutes.getTagsAPIUrl + Helper.getQueryParamsString(params);
    return this.httpClient
      .get<any>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + 'cmFtOk1haGlAMTIzNA=='
        })
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  _getSingleTagAPI(tagId, params: any = {}) {
    const url = this.getApiRoutes.getTagsAPIUrl + '/' + tagId + Helper.getQueryParamsString(params);
    return this.httpClient
      .get<any>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + 'cmFtOk1haGlAMTIzNA=='
        })
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  _deleteTagAPI(tagId, params: any = {}) {
    const url = this.getApiRoutes.getTagsAPIUrl + '/' + tagId + Helper.getQueryParamsString(params);
    return this.httpClient
      .delete<any>(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + 'cmFtOk1haGlAMTIzNA=='
        })
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

}
