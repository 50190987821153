import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { CommomService } from '../_common/common.service';
import { BaseService } from '../_base/base.service';

@Injectable({
  providedIn: 'root'
})


export class FormsService extends BaseService {

  //constructor(private commonService: CommomService) {}


  getEntity(entityName, params = {}) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.get<any>(url, { params }).pipe(
      map(data => {
        return data;
      })
    );
  }

  getEntityById(entityName, entityId) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}/${entityId}`;
    return this.httpClient.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getMerchantById(entityName) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

  getEntityByPid(entityName, params = {}) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.get<any>(url, {params}).pipe(
      map(data => {
        return data;
      })
    );
  }

  getAddressById(entityName, payload) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.post<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  createEntity(entityName, payload) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.post<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  updateInventory(entityName, payload) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.post<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  updateEntity(entityName, entityId, payload) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.post<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  deleteEntity(entityName, entityId) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.post<any>(url, entityId).pipe(
      map(data => {
        return data;
      })
    );
  }

  deleteEntities(entityName, payload) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.post<any>(url, payload).pipe(
      map(data => {
        return data;
      })
    );
  }

  getEntityCount(entityName) {
    const url = `${this.getApiRoutes.getAbstractAPIUrl}/${entityName}`;
    return this.httpClient.get<any>(url).pipe(
      map(data => {
        return data;
      })
    );
  }

}