import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { map, filter } from 'rxjs/operators';
import { BaseService } from '../_base/base.service';
import { Helper } from 'src/app/_utils';



@Injectable({
  providedIn: 'root'
})

export class AssetsUploadService extends BaseService {

  uploadImageAPI(data, params: any = {}) {
    const url = this.getApiRoutes.getImageUploadAPIUrl + Helper.getQueryParamsString(params);
    return this.httpClient
    .post<any>(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + 'cmFtOk1haGlAMTIzNA=='
      })
    })
    .pipe(
      map(response => {
        return response;
      })
    );
  }

  uploadImageAPI2(data, params: any = {}) {
    const url = this.getApiRoutes.getImageUploadAPIUrl + Helper.getQueryParamsString(params);
    const req = new HttpRequest('POST', url, data, {
    });
    return this.httpClient
      .request(req)
      .pipe(filter(e => e instanceof HttpResponse));
  }

}
