import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import { User } from 'src/app/_models';

const users: User[] = [{ id: 1, userName: 'test', password: 'test', firstName: 'Test', lastName: 'User' }];

@Injectable()
export class MockAPIDataInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, method, headers, body } = request;

    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());

    function handleRoute() {
      switch (true) {
        case url.endsWith('/users/authenticate') && method === 'POST':
          return authenticate();
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }

    // route functions
    function authenticate() {
      const { userData } = body;
      const user = users.find(x => x.userName === userData.userName && x.password === userData.password);
      if (!user) { return ok({ code: 401, message: 'userName or Password is incorrect' }); }
      return ok({
        code: 200,
        message: 'User Successfully Logged In',
        data: {
          id: user.id,
          userName: user.userName,
          firstName: user.firstName,
          lastName: user.lastName,
          token: 'mock-jwt-token'
        }
      });

    }

    function getUsers() {
      if (!isLoggedIn()) { return unauthorized(); }
      return ok(users);
    }

    // helper functions
    function ok(body?: any) {
      return of(new HttpResponse({ status: 200, body }));
    }

    function error(message) {
      return throwError({ error: { message } });
    }

    function unauthorized() {
      return throwError({ status: 401, error: { message: 'Unauthorised' } });
    }

    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer mock-jwt-token';
    }
  }

}

export let mockAPIDataProvider = {

  // use mock backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: MockAPIDataInterceptor,
  multi: true

};
