import { FormDataModel } from 'src/app/_models';

const CmsRawArray = [
  {
    name: 'name',
    placeholder: 'Enter Name Here',
    isTableHeader: true,
    tableMeta: { title: 'Name', enableSort: true },
    label: 'Name',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: true,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'slug',
    placeholder: 'Enter Slug Here',
    isTableHeader: true,
    tableMeta: { title: 'Sulg', enableSort: true },
    label: 'Slug',
    fieldType: {
      name: 'text',
      subfieldType: 'text'
    },
    required: true,
    readonly: false,
    errorMsg: 'Can\'t be left blank',
    default: ''
  },
  {
    name: 'data',
    placeholder: 'Ex :> {"name": "text"}',
    tableMeta: { title: 'JSON', enableSort: false },
    label: 'JSON Data',
    formMeta: { width: 100 },
    id: 4,
    fieldType: {
        name: 'jsonText',
        subfieldType: 'jsonText'
    },
    required: false,
    readonly: false,
    errorMsg: 'JSON Invalid',
    default: ''
}
];

export const CmsForm: FormDataModel = new FormDataModel(CmsRawArray);
